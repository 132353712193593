@import '../../assets/scss/helpers/mixins';
@import '../../assets/scss/helpers/variables';

.offer {
  position: relative;
  padding: rem(152) 0 rem(56);
  h1 {
    max-width: rem(640);
  }
}

.embed__contain {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      76.53% 48.76% at 76.53% 51.24%,
      rgba(0, 0, 0, 0.32) 20.39%,
      rgba(0, 0, 0, 0.42) 57.59%,
      rgba(0, 0, 0, 0.8) 100%
    );
    z-index: 1;
  }
}

.embed__contain img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
}

.container {
  margin: 0 auto;
  max-width: 120rem;
  padding: 0 3.5rem;
  width: 100%;
}

.embed__contain iframe,
.embed__contain object,
.embed__contain embed,
.embed__contain video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.foot {
  display: flex;
  justify-content: space-between;
  margin-top: rem(50);
}

.btn {
  width: rem(260);
}

.socials {
  display: flex;
}

.vk {
  width: rem(56);
  margin-right: rem(24);
  svg {
    width: rem(29);
    height: rem(18);
    fill: #ffffff;
  }
}

.dzen {
  font-size: rem(16);
  line-height: rem(17);
  padding-left: rem(24);
  padding-right: rem(24);

  svg {
    width: rem(70);
    height: rem(20);
    margin-left: rem(8);
  }
}
@media screen and (max-width: $lgPhoneWidth) {
  .offer {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: rem(32);
  }

  .container {
    padding: 0 1rem;
  }

  .dzen,
  .vk {
    display: none;
  }
}

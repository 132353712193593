@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';

.videoSection {
  padding: rem(80) 0;
  background-color: #f1f1eb;
  text-align: center;
  color: #373a21;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.title {
  margin: 0 auto rem(20);
}

.subtitle {
  margin-bottom: rem(30);
  color: #6a6f48;
  font-size: rem(18);
}

.videoWrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  box-shadow: 0 0 rem(10) rgba(0, 0, 0, 0.15);
  margin-bottom: rem(30);
  border-radius: rem(32);
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: rem(3) solid #2d3a20;
  border-radius: rem(32);
}

@media (max-width : $lgPhoneWidth) {
  .videoSection .container {
    width: 95%;
  }

  .videoSection .title {
    text-align: left;
    font-size: rem(24);
  }
  
  .videoSection .subtitle {
    text-align: left;
    font-size: rem(16);
  }

  .videoSection {
    padding: rem(50) 0;
  }
}
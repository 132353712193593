@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';

.about {
  position: relative;
  padding: rem(80) 0 rem(56);
  h2 {
    max-width: rem(540);
  }
  ol {
    display: grid;
    gap: rem(6);
    max-width: rem(602);
    margin: rem(48) 0 rem(120);
    counter-reset: my-awesome-counter;
    li {
      position: relative;
      font-weight: 400;
      font-size: rem(18);
      line-height: 160%;
      padding: rem(12) rem(32);
      counter-increment: my-awesome-counter;
      &::before {
        content: counter(my-awesome-counter);
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 900;
        font-size: rem(18);
        line-height: 160%;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        left: 0;
        top: rem(12);
      }
    }
  }
}

.container {
  position: relative;
}

.link {
  display: flex;
  justify-content: space-between;
  max-width: rem(360);
  padding: 0 rem(20) 0 rem(32);
  svg {
    width: rem(24);
    height: rem(24);
  }
}

.descr {
  position: absolute;
  bottom: 0;
  right: rem(56);
  padding: rem(24) rem(32);
  min-height: rem(235);
  max-width: rem(570);
  font-weight: 700;
  font-size: rem(20);
  line-height: 160%;
  text-transform: uppercase;
  color: #373a21;
  span {
    position: relative;
  }
  b {
    color: #7ab604;
    position: relative;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .about {
    position: relative;
    padding-top: rem(34);
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #738941 0%, rgba(119, 143, 60, 0) 29.18%),
        linear-gradient(239.9deg, rgba(205, 49, 0, 0.4) 8.06%, rgba(140, 195, 45, 0) 100%);
    }
    ol {
      margin-top: rem(120);
      margin-bottom: 0;
      li {
        padding: rem(12) 0 rem(12) rem(24);
      }
    }
  }
  .descr {
    display: none;
  }
  .link {
    display: none;
  }
}

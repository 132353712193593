@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';

.benefits {
  position: relative;
  color: #373a21;
}

.title {
  max-width: rem(860);
}

.container {
  padding-top: rem(60);
  padding-bottom: rem(90);
  height: 95vh;
  max-height: rem(900);
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  margin: auto 0;
}

.text {
  max-width: rem(602);
  b {
    font-weight: 500;
    font-size: rem(32);
    line-height: 140%;
    margin-bottom: rem(30);
  }
  p {
    font-family: 'Raleway', sans-serif;
    margin-top: rem(16);
    font-weight: 500;
    font-size: rem(18);
    line-height: 180%;
  }
}

.list {
  position: relative;
  margin-top: auto;
  text-align: right;
  font-size: 2.5rem;
  line-height: 140%;
}

.img {
  position: absolute;
  width: 60%;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  opacity: 0.4;
}

.brand {
  position: relative;
  padding: rem(80) 0;
  color: #373a21;
  background: linear-gradient(90deg, #f1f1eb 0%, #e4dfe1 100%, #e4dfe1 100%);
}

.wrap {
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.my_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.diplom {
  width: 100%;
  max-width: rem(580);
  border-radius: 1.5rem;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: $lgPhoneWidth) {
  .my_container {
    flex-direction: column;
  }
  .brand {
    h2 {
      font-size: rem(24);
      margin-bottom: rem(32);
    }
  }
  .diplom {
    margin-top: 2rem;
  }
  .list {
    font-size: 1.5rem;
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    li {
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
      border: 1px solid #373a21;
      border-radius: 0.5rem;
      background-color: #f1f1eb;
      width: 100%;
    }
  }
  .img {
    width: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
  }
}

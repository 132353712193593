@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';

.blog {
  position: relative;
}
.content {
  padding: rem(56) rem(56) rem(56) 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  padding-right: 0;
  min-height: rem(800);
}
.foot {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.link {
  width: rem(349);
}
.vk {
  width: rem(56);
  height: rem(56);
  svg {
    width: rem(29);
  }
}
.dzen {
  width: rem(124);
  height: rem(56);
  svg {
    width: rem(72);
  }
}
.socials {
  display: flex;

  > *:not(:first-child) {
    margin-left: rem(24);
  }
}
.sidebar {
  padding: rem(64) rem(24) rem(64) 0;
  display: grid;
  gap: rem(20);
  grid-template-columns: repeat(2, minmax(rem(320), rem(412)));
  max-height: rem(900);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: rem(24);
  margin-right: rem(24);
  &::-webkit-scrollbar {
    width: rem(4);
    background-color: transparent;
    &-track {
      background-color: transparent;
    }
    &-thumb {
      background-color: #ffffff;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: rem(4) rem(4) rem(16);
  border-radius: rem(28);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    img {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }

  b {
    font-weight: 400;
    font-size: rem(20);
    line-height: 150%;
    color: #373a21;
    margin-bottom: rem(16);
    padding: 0 rem(18);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }
  i {
    margin-top: auto;
    font-weight: 400;
    font-size: rem(12);
    line-height: 140%;
    color: #373a21;
    opacity: 0.56;
    padding: 0 rem(18);
  }
}
.img {
  position: relative;
  width: 100%;
  height: rem(240);
  margin-bottom: rem(20);
  overflow: hidden;
  border-radius: rem(24);
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .blog {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        83.37% 106.53% at 30.5% 84.03%,
        rgba(45, 72, 29, 0) 39.49%,
        #1f421c 97.89%
      );
      backdrop-filter: blur(1px);
      z-index: 0;
      transform: scale(-1, 1);
    }
  }
  .container {
    min-height: auto;
    grid-template-columns: 1fr;
    flex-direction: column;
    padding: 0;
  }
  .content {
    position: relative;
    height: rem(320);
    padding-right: rem(16);
    padding-left: rem(16);
    padding-bottom: rem(16);
  }
  .link {
    display: none;
  }
  .sidebar {
    padding-left: rem(8);
    padding-right: rem(8) !important;
    position: relative;
    grid-template-columns: 1fr;
    max-height: rem(540);
    margin-right: 0;
    padding-right: 0;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';

.delivery {
  overflow: hidden;
  background: linear-gradient(278.73deg, #759823 29.18%, #92b347 100%);
}

.mob {
  display: none;
}

.container {
  position: relative;
  padding-top: rem(60);
  padding-bottom: rem(60);
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100vh;
    background-color: #d4f0b0;
    display: block;
    top: 0;
    right: 2px;
    transform: translateX(100%);
  }
}

.title {
  font-family: 'Prosto One', sans-serif;
  margin-bottom: rem(32);
  max-width: rem(800);
}

.btn {
  width: rem(280);
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(rem(299), 1fr));
  gap: rem(48);
  padding-top: rem(32);
  border-top: rem(4) solid #ffffff;
  max-width: rem(994);
  font-weight: 400;
  font-size: rem(16);
  line-height: 180%;
  margin-top: rem(48);
}

.img {
  right: rem(-190);
  bottom: 0;
  position: absolute;
  width: rem(1040);
  height: 100%;
}

@media screen and (max-width: $lgPhoneWidth) {
  .container {
    padding-top: rem(40);
    &::before {
      display: none;
    }
  }
  .btn {
    width: rem(260);
  }
  .mob {
    display: block;
    width: 100%;
  }
  .img {
    display: none;
  }
  .list {
    gap: rem(32);
    max-width: 100%;
  }
}

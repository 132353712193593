@import '../../../assets/scss/helpers/mixins';
@import '../../../assets/scss/helpers/variables';

.header {
  top: 0;
  position: fixed;
  background: transparent;
  height: rem(96);
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 10;
  color: #373a21;
  height: rem(66);
  &::before {
    max-width: 100%;
    height: 100%;
  }
  .burger {
    i {
      background-color: #373a21;
    }
  }
  transition: color 0.3s, height 0.3s;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: rem(3);
    max-width: rem(1708);
    max-width: 100%;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    transition: max-width 0.3s, height 0.3s;
  }
}

.skolkovo {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: auto !important;
  margin-left: rem(32);
  font-weight: 900;
  img {
    margin-right: rem(16);
    width: rem(120);
  }
}

.burger {
  display: none;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  position: relative;
  width: rem(204);
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: rem(18);
  line-height: 125%;
  text-transform: uppercase;
  > *:not(:last-child) {
    margin-right: rem(32);
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .header {
    height: rem(72);
    &::before {
      transition: max-width 0.3s, height 0.3s, transform 0.3s;
    }
    &.scrolling {
      height: rem(54);
    }
    &.open {
      color: #373a21;
      height: rem(54);
      &::before {
        max-width: 100%;
        height: calc(102vh);
        transform: translate(-50%, calc(100% - 4.125rem));
      }
      .nav {
        opacity: 1;
        visibility: visible;
      }
      .burger {
        i {
          background-color: #373a21;
          &:nth-child(1) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }
  }

  .logo {
    margin-bottom: rem(-4);
    width: rem(153);
  }
  .nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    transform: translateY(100%);
    padding: rem(16);
    opacity: 0;
    visibility: hidden;
    font-size: rem(20);
    line-height: 125%;
    > * {
      margin-bottom: rem(32);
    }
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
  .skolkovo {
    margin: 0 auto;
  }
  .burger {
    display: block;
    position: relative;
    width: rem(19);
    height: rem(20);
    &::before {
      position: absolute;
      content: '';
      width: rem(40);
      height: rem(40);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    i {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: rem(20);
      height: rem(3);
      background-color: #ffffff;
      transition: transform 0.3s, top 0.3s;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:nth-child(3) {
        top: 100%;
      }
    }
  }
}

@import '../../assets/scss/helpers/mixins';
@import '../../assets/scss/helpers/variables';

.container {
  padding-top: rem(40);
  padding-bottom: rem(40);
  display: grid;
  gap: rem(52);
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: $lgPhoneWidth) {
    grid-template-columns: 1fr;
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: rem(4) rem(4) rem(16);
  border-radius: rem(28);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    img {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }

  b {
    font-weight: 400;
    font-size: rem(20);
    line-height: 150%;
    color: #373a21;
    margin-bottom: rem(16);
    padding: 0 rem(18);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }
  i {
    margin-top: auto;
    font-weight: 400;
    font-size: rem(12);
    line-height: 140%;
    color: #373a21;
    opacity: 0.56;
    padding: 0 rem(18);
  }
}
.img {
  position: relative;
  width: 100%;
  height: rem(240);
  margin-bottom: rem(20);
  overflow: hidden;
  border-radius: rem(24);
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
  }
}

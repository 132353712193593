@import '../../assets/scss/helpers/mixins';
@import '../../assets/scss/helpers/variables';

.offer {
  text-align: center;
  position: relative;
  padding: rem(200) 0 rem(100);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%),
      radial-gradient(100% 100% at 100% 0%, rgba(237, 238, 231, 0) 0%, #edeee7 100%);
  }
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.title {
  font-family: 'Prosto One', sans-serif;
  position: relative;
  font-weight: 500;
  font-size: rem(48);
  line-height: 140%;
  max-width: rem(880);
  margin-bottom: rem(32);
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.socials {
  position: relative;
  display: flex;
}

.vk {
  width: rem(56);
  margin-right: rem(24);
  svg {
    width: rem(29);
    height: rem(18);
    fill: #ffffff;
  }
}

.dzen {
  font-size: rem(16);
  line-height: rem(17);
  padding-left: rem(24);
  padding-right: rem(24);

  svg {
    width: rem(70);
    height: rem(20);
    margin-left: rem(8);
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.article {
  padding: rem(40) 0;
}

.images {
  position: sticky;
  top: rem(80);
  height: fit-content;
  display: grid;
  gap: rem(16);
  width: rem(740);
  img {
    width: 100%;
    border-radius: rem(20);
  }
}

.text {
  display: flex;
  flex-direction: column;
  max-width: rem(1024);
  margin: 0 auto;
  h2,
  h3,
  h4 {
    color: #373a21;
    margin: rem(24) 0 rem(12);
  }
  h2 {
    font-size: rem(32);
  }
  h3 {
    font-size: rem(28);
  }
  h4 {
    font-size: rem(24);
  }
  b,
  strong {
    margin-bottom: rem(24);
    font-weight: 700;
    font-size: rem(20);
    line-height: 160%;
    color: #373a21;
  }
  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: rem(20);
    line-height: 180%;
    color: #373a21;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  ul {
    margin-bottom: rem(24);
    li {
      position: relative;
      color: #373a21;
      font-weight: 300;
      font-size: rem(20);
      line-height: 120%;
      padding-left: rem(16);
      &::before {
        position: absolute;
        content: '';
        top: 0.75rem;
        left: 0;
        width: rem(6);
        height: rem(6);
        background-color: #373a21;
        border-radius: 100%;
      }
      &:not(:last-child) {
        margin-bottom: rem(16);
      }
    }
  }
  a {
    display: inline-block;
    margin: 0 rem(4);
    color: #2d7ec5;
  }
  img {
    width: 100%;
    margin-bottom: rem(40);
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .offer {
    padding-bottom: rem(40);
  }
  .dzen {
    padding-left: rem(16);
    padding-right: rem(16);
    svg {
      margin-left: 0;
    }
    span {
      display: none;
    }
  }
  .title {
    font-size: rem(24);
    margin-bottom: rem(80);
  }
  .socials {
    margin-top: auto;
  }
  .container {
    flex-direction: column-reverse;
  }
  .images {
    width: 100%;
  }
  .text {
    h2 {
      font-size: rem(20);
    }
    h3 {
      font-size: rem(20);
    }
    h4 {
      font-size: rem(18);
    }
    b {
      font-size: rem(16);
    }
    p {
      font-size: rem(16);
      line-height: 120%;
    }
    ul {
      li {
        font-size: rem(16);
      }
    }
  }
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}.woff') format('woff'), url('#{$file-path}.woff2') format('woff2'),
      url('#{$file-path}.svg') format('svg'), url('#{$file-path}.ttf') format('ttf'),
      url('#{$file-path}.ttf') format('truetype');
  }
}

@include font-face('Raleway', '../../fonts/Raleway/Raleway-Bold', 700);
@include font-face('Raleway', '../../fonts/Raleway/Raleway-SemiBold', 600);
@include font-face('Raleway', '../../fonts/Raleway/Raleway-Medium', 500);

@include font-face('Prosto One', '../../fonts/ProstoOne/ProstoOne-Regular', 400);

// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-Black', 900);
// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-ExtraBold', 800);
// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-Bold', 700);
// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-SemiBold', 600);
// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-Medium', 500);
// @include font-face('Unbounded', '../../fonts/Unbounded/Unbounded-Regular', 400);

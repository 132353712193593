@import '../../../assets/scss/helpers/mixins';
@import '../../../assets/scss/helpers/variables';

.footer {
  padding: rem(80) 0 rem(72);
  background-color: #5a7583;
}

.logo {
  max-width: rem(500);
  img {
    width: rem(204);
    margin-bottom: rem(20);
  }
}

.address {
  display: flex;
  font-weight: 400;
  font-size: rem(16);
  line-height: 160%;
  svg {
    min-width: rem(24);
    height: rem(24);
    fill: #ffffff;
    margin-right: rem(16);
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: rem(1358);
  width: 100%;
}

.contacts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: rem(605);
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  a {
    padding: rem(6) rem(12);
    font-weight: 800;
    font-size: rem(16);
    line-height: 160%;
    margin-top: rem(12);
    text-transform: none;
    height: fit-content;
    width: fit-content;
    svg {
      width: rem(18);
      height: rem(18);
      fill: #ffffff;
      margin-right: rem(4);
    }
  }
}

.label {
  font-weight: 500;
  font-size: rem(16);
  line-height: 160%;
  text-transform: uppercase;
  margin-bottom: rem(2);
}

.foot {
  margin-top: rem(24);
  a {
    font-weight: 700;
    font-size: rem(16);
    line-height: 140%;
    text-decoration-line: underline;
    &:hover {
      text-decoration-line: none;
    }
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .footer {
    padding: rem(48) 0;
  }
  .address {
    font-size: rem(12);
    line-height: 160%;
    margin-bottom: rem(40);
    margin-bottom: 0;
  }
  .list {
    margin-top: rem(40);
  }
  .foot {
    margin-top: rem(40);
  }
}

@import '../../../../assets/scss/helpers/mixins';
@import '../../../../assets/scss/helpers/variables';
@import '../../../../assets/scss/base/animations';

.catalog {
  position: relative;
}

.container {
  position: relative;
}

.tabs {
  padding: rem(76) 0 rem(120);
  font-weight: 500;
  font-size: rem(48);
  line-height: 125%;
  text-transform: uppercase;
  li {
    font-family: 'Prosto One', sans-serif;
    position: relative;
    cursor: pointer;
    width: fit-content;
    opacity: 0.56;
    transition: opacity 0.3s;
    &::before {
      position: absolute;
      content: '';
      bottom: rem(-2);
      left: 0;
      width: 0;
      height: rem(4);
      background-color: #ffffff;
      transition: width 0.3s;
    }
    &:not(:last-child) {
      margin-bottom: rem(32);
    }
    &:hover {
      opacity: 0.8;
    }
    &.active {
      opacity: 1;
      &::before {
        width: 100%;
      }
    }
    i {
      font-weight: 500;
      font-size: rem(16);
      line-height: 240%;
    }
  }
}

.arrows {
  position: absolute;
  bottom: rem(24);
  left: 50%;
  transform: translateX(-50%);
  max-width: rem(1708);
  width: 100%;
  display: flex;
  justify-content: space-between;
  animation: fadeIn 0.3s;

  > * {
    width: rem(56);
    height: rem(56);
    &:hover {
      svg {
        stroke: #ffffff;
      }
    }
    svg {
      stroke: #000000;
      width: rem(27);
      transition: stroke 0.3s;
    }
  }
}
.next {
  svg {
    transform: scale(-1, 1);
  }
}
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100vw;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
  transition: all 0.3s;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  > * {
    padding-right: rem(4);
  }
}

.card {
  min-width: rem(292);
  max-width: rem(292);
  animation: fadeIn 0.3s;
  b {
    font-family: 'Prosto One', sans-serif;
    padding: 0 rem(24);
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: rem(70);
    font-weight: 600;
    font-size: rem(18);
    line-height: rem(20);
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  &:hover {
    .overlay {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.content {
  position: relative;
  width: 100%;
  height: rem(388);
  overflow: hidden;
  background-color: #ffffff;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #498b27;
  border: rem(3) solid #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: rem(24);
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: rem(16);
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: rem(46);
  }
}

.table {
  font-family: 'Prosto One', sans-serif;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(15);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    opacity: 0.48;
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .tabs {
    padding-top: rem(48);
    font-size: rem(32);
  }

  .table {
    span {
      font-size: rem(10);
    }
    strong {
      font-size: rem(12);
    }
  }

  .card {
    min-width: rem(220);
    max-width: rem(220);
  }
  .content {
    height: rem(296);
  }

  .overlay {
    padding: rem(12);
    p {
      font-size: rem(12);
      margin-bottom: rem(16);
    }
  }

  .arrows {
    padding: 0 rem(16);
    > * {
      width: rem(56);
    }
  }
}

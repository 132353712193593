/* Vars:
  ========================================================================== */

// Font
$mainFont: 'Raleway', sans-serif;

// Size
$mainFontColor: #ffffff;
$mainFontSize: 16px;
$mainFontWeight: 400;
$mainLineHeight: 140%;

$desktopWidth: 1920px;
$lgTableWidth: 992px;
$tableWidth: 768px;
$lgPhoneWidth: 576px;
$phoneWidth: 480px;
$smPhoneWidth: 375px;

// Main color
$bgColor: #ffffff;
$defaultColor: #ffffff;

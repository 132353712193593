@import '../../assets/scss/helpers/mixins';
@import '../../assets/scss/helpers/variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  
}

.close {
  display: none;
}

.active {
  opacity: 1;
  visibility: visible;
  .overlay {
    opacity: 1;
    visibility: visible;
  }
  .content {
    transform: translateX(0);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(18, 33, 22, 0.8), rgba(18, 33, 22, 0.8));
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.content {
  position: absolute;
  width: rem(720);
  right: 0;
  top: 0;
  max-height: 100vh;
  height: 100%;
  transform: translateX(100%);
  background: #edeee7;
  overflow-y: auto;
  transition: transform 0.3s;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}

.title {
  font-family: 'Prosto One', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem(48);
  line-height: 130%;
  padding: rem(86) rem(40) rem(24);
  background: linear-gradient(79.65deg, #ca8468 0%, #b2aa6b 55.98%);
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-content: start;
  gap: rem(4);
  padding: rem(4);
  img {
    width: rem(354);
    height: rem(145);
  }
  a {
    transition: transform 0.3s;
    &:hover {
      transform: translateY(rem(4));
    }
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .content {
    width: 100%;
  }
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .title {
    font-size: rem(36);
    line-height: 125%;
    padding-bottom: rem(24);
    padding-left: rem(24);
  }
  .close {
    cursor: pointer;
    display: block;
    width: rem(18);
    height: rem(18);
    position: absolute;
    top: rem(16);
    right: rem(16);
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@import '../../assets/scss/helpers/mixins';
@import '../../assets/scss/helpers/variables';

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: -1;
}

* {
  box-sizing: border-box;
}

html {
  color: $mainFontColor;
  scroll-behavior: smooth;
  font-size: 16px;

  @media (max-width: $desktopWidth) {
    font-size: calcFluidFontSize(4.80001, 17, 576, 1920);
  }
  @media (max-width: $lgTableWidth) {
    // @todo
  }
  @media (max-width: $lgPhoneWidth) {
    font-size: calcFluidFontSize(12.4446, 25.5998, 280, 576);
  }
}

body {
  font-family: $mainFont;
  background: linear-gradient(0deg, #edeee7, #edeee7), #f0efe5;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}

// main {
//   overflow: hidden;
// }

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.container {
  width: 100%;
  max-width: rem(1920);
  margin: 0 auto;
  padding: 0 rem(56);
}

h1 {
  font-family: 'Prosto One', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem(76);
  line-height: 125%;
}

h2,
h3,
h4 {
  font-family: 'Prosto One', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem(48);
  line-height: 150%;
}

.button,
.link {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(56);
  border-radius: rem(210);
  font-weight: 600;
  font-size: rem(20);
  line-height: rem(25);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border: rem(4) solid #ffffff;
  transition: background-color 0.3s, color 0.3s;
}

.button {
  background: #ffffff;
  color: #373a21;
  &:hover {
    color: #fff;
    background-color: transparent;
  }
}

.link {
  color: #ffffff;
  svg {
    fill: #ffffff;
    transition: fill 0.3s;
  }

  &:hover {
    color: #373a21;
    background-color: #fff;
    svg {
      fill: #373a21;
    }
  }
}

@media screen and (max-width: $lgPhoneWidth) {
  .container {
    padding: 0 rem(16);
  }
  h1 {
    font-size: rem(36);
  }
  h2 {
    font-size: rem(32);
    line-height: 125%;
  }

  .button,
  .link {
    font-size: rem(18);
    line-height: rem(16);
  }
}
table { 
  border-radius: 0.5rem; 
  color: rgba(0, 0, 0, 0.75); 
  border: 1px solid rgba(0, 0, 0, 0.5); 
  font-size: 1rem; 
  line-height: 120%; 
  background-color: rgba(255, 255, 255, 0.5); 
  border-collapse: collapse; 
  width: 100%; 
  max-width: 60%; 
  margin: 0 0 1.5rem 0; 
} 
th, td { 
  padding: 1rem; 
  text-align: left; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.5); 
} 
th { 
  font-weight: bold; 
} 
tr:hover { 
  background-color: rgba(0, 0, 0, 0.1); 
}
@import '../../assets/scss/helpers/variables';
@import '../../assets/scss/helpers/mixins';

.notpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #373a21;
  padding-top: rem(80);
}

.title {
  font-family: 'Prosto One', sans-serif;
  font-size: rem(220);
  margin-bottom: rem(32);
}

.back {
  font-size: rem(24);
  max-width: rem(460);
  margin: 0 auto;
}

@media screen and (max-width: $lgPhoneWidth) {
  .title {
    font-size: rem(80);
  }

  .notpage {
    min-height: rem(400);
  }

  .back {
    font-size: rem(18);
  }
}
